<template>
  <b-modal
    id="edit-emma-modal"
    :no-close-on-backdrop="true"
    cancel-variant="outline-secondary"
    :ok-title="$t('save')"
    :cancel-title="$t('cancel')"
    centered
    :title="$t('editEmma', { serial: emma.serial })"
    @hidden="resetModal"
    @show="loadData"
    @ok="handleSubmit"
  >
    <b-form>
      <b-form-group>
        <!--        <b-card>-->
        <h6 :style="selectedOrganization ? '' : 'color: #F97794'">
          {{ $t('organisation') }}
        </h6>
        <v-select
          v-model="selectedOrganization"
          :options="selectableOrganizations"
          :get-option-label="(option) => option.name"
          class="mb-1"
          :style="selectedOrganization ? '' : 'box-shadow: 0px 0px 2px 2px #F97794'"
        />
        <h6>{{ $t('county') }}</h6>
        <v-select
          v-model="selectedSuborganization"
          :options="selectableSuborganizations"
          :get-option-label="(option) => option.name"
          class="mb-1"
        />
        <h6>{{ $t('house') }}</h6>
        <v-select
          v-model="selectedCluster"
          :options="selectableClusters"
          :get-option-label="(option) => option.name"
          class="mb-1"
        />
        <h6>{{ $t('flat') }}</h6>
        <v-select
          v-model="selectedLocalGroup"
          :options="selectableLocalGroups"
          :get-option-label="(option) => option.name"
          class="mb-1"
        />
        <!--        </b-card>-->
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BForm, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'EditEmmaForm',
  components: {
    BFormGroup,
    BForm,
    vSelect,
  },
  props: {
    emma: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedOrganization: undefined,
      selectedSuborganization: undefined,
      selectedCluster: undefined,
      selectedLocalGroup: undefined,
    }
  },
  computed: {
    selectableOrganizations() {
      return this.$store.state.organizations.organizations
    },
    selectableSuborganizations() {
      if (this.selectedOrganization !== undefined && this.selectedOrganization !== null) {
        return this.$store.getters['suborganizations/getSuborganizationByOrganizationId'](parseInt(this.selectedOrganization.id, 10))
      }
      return []
    },
    selectableClusters() {
      if (this.selectedSuborganization !== undefined && this.selectedSuborganization !== null) {
        return this.$store.getters['clusters/getClusterBySuborganizationId'](parseInt(this.selectedSuborganization.id, 10))
      }
      return []
    },
    selectableLocalGroups() {
      if (this.selectedCluster !== undefined && this.selectedCluster !== null) {
        return this.$store.getters['localGroups/getLocalGroupsByClusterId'](parseInt(this.selectedCluster.id, 10))
      }
      return []
    },

  },
  watch: {
    selectedOrganization(newVal, oldVal) {
      if (oldVal !== undefined && newVal !== undefined) {
        this.selectedSuborganization = undefined
        this.selectedCluster = undefined
        this.selectedLocalGroup = undefined
      }
    },
    selectedSuborganization(newVal, oldVal) {
      if (oldVal !== undefined && newVal !== undefined) {
        this.selectedCluster = undefined
        this.selectedLocalGroup = undefined
      }
    },
    selectedCluster(newVal, oldVal) {
      if (oldVal !== undefined && newVal !== undefined) {
        this.selectedLocalGroup = undefined
      }
    },
  },
  mounted() {
  },
  methods: {
    async handleSubmit(bvModalEvent) {
      bvModalEvent.preventDefault()
      if (this.checkValidity()) {
        const payload = {
          emma: this.emma,
          organizationData: {
            selectedOrganization: this.selectedOrganization.id,
          },
        }
        if (this.selectedSuborganization) {
          payload.organizationData.selectedSuborganization = this.selectedSuborganization.id
        }
        if (this.selectedCluster) {
          payload.organizationData.selectedCluster = this.selectedCluster.id
        }
        if (this.selectedLocalGroup) {
          payload.organizationData.selectedLocalGroup = this.selectedLocalGroup.id
        }
        try {
          await this.$store.dispatch('emmas/updateEmma', payload)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Emma erfolgreich bearbeitet',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            this.$bvModal.hide('edit-emma-modal')
          })
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Emma konnte nicht bearbeitet werden',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    checkValidity() {
      if (this.selectedOrganization) {
        return true
      }
      return false
    },
    loadData() {
      this.selectedOrganization = this.getEmmaOrg(this.emma.organizationId)
      this.selectedSuborganization = this.getEmmaSuborganization(this.emma.suborganizationId)
      this.selectedCluster = this.getEmmaCluster(this.emma.clusterId)
      this.selectedLocalGroup = this.getEmmaLocalGroup(this.emma.localGroupId)
    },
    resetModal() {
      this.selectedOrganization = undefined
      this.selectedSuborganization = undefined
      this.selectedCluster = undefined
      this.selectedLocalGroup = undefined
    },
    getEmmaLocalGroup(id) {
      return this.$store.state.localGroups.localGroups.find(localGroup => localGroup.id === id)
    },
    getEmmaCluster(id) {
      return this.$store.state.clusters.clusters.find(cluster => cluster.id === id)
    },
    getEmmaSuborganization(id) {
      return this.$store.state.suborganizations.suborganizations.find(suborganization => suborganization.id === id)
    },
    getEmmaOrg(id) {
      return this.$store.state.organizations.organizations.find(organization => organization.id === id)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
